<template>
  <div class="add-team">
    <Button
      theme="secondary"
      class="add-team__create-btn"
      @click="showCreateTeamModal = true"
    >
      <inline-svg
        class="add-team__create-icon"
        :src="require(`../assets/icons/Plus.svg`)"
        aria-label="Image"
      ></inline-svg>
      {{ $t("pages.settings.linkedTeams.child.createButton") }}
    </Button>
    <CreateTeam
      v-if="showCreateTeamModal"
      @close="showCreateTeamModal = false"
    ></CreateTeam>

    <!-- <Button theme="secondary" @click="addToBranches">
      add branch to each slide
    </Button> -->
  </div>
</template>

<script>
import Button from "@/components/Button";
import CreateTeam from "@/components/CreateTeam";
// import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Button,
    CreateTeam
  },
  data: () => ({
    showCreateTeamModal: false
  })
  // computed: {
  //   ...mapGetters("teams", {
  //     team: "getCurrentTeam"
  //   })
  // },
  // methods: {
  //   ...mapActions("teams", ["updateTeam"]),
  //   addToBranches() {
  //     this.team.slides.forEach(slide => {
  //       let branchExists = slide.branches.some(
  //         branch => branch.id === "-O6vGYGFOrOSbswQOxk_"
  //       );

  //       if (!branchExists) {
  //         slide.branches.push({
  //           active: false,
  //           id: "-O6vGYGFOrOSbswQOxk_",
  //           name: "11151 Radio Jeske",
  //           avatar: ""
  //         });
  //       }
  //     });

  //     this.updateTeam(this.team);
  //   }
  // }
};
</script>

<style lang="scss">
.add-team {
  min-height: 100vh;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__create-icon {
    margin-right: 0.5rem;
  }
}
</style>
