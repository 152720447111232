<template>
  <div class="create-team">
    <Modal @close="onClose" :heading="$t('components.createTeam.title')">
      <div class="create-team__content" slot="modal-content">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form
            class="create-team__form"
            @submit.prevent="handleSubmit(create)"
          >
            <ValidationProvider
              class="create-team__field"
              name="TeamTitle"
              rules="required"
              v-slot="{ errors }"
            >
              <label class="create-team__label" for="TeamTitle">{{
                $t("components.createTeam.label")
              }}</label>
              <input class="create-team__input" v-model="team" type="text" />
              <span class="create-team__error">{{ errors[0] }}</span>
            </ValidationProvider>

            <div class="create-team__loading-bar"></div>
            <Button class="create-team__upload-btn" type="submit">
              {{ $t("components.createTeam.createButton") }}
            </Button>
          </form>
        </ValidationObserver>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapActions } from "vuex";
import Button from "@/components/Button";

extend("required", required);

export default {
  components: {
    Modal,
    ValidationProvider,
    ValidationObserver,
    Button
  },
  data: () => ({
    team: ""
  }),
  methods: {
    ...mapActions("teams", ["createChildTeam"]),
    create() {
      this.createChildTeam(this.team);
      this.onClose();
    },
    onClose() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.create-team {
  &__content {
    display: flex;
    flex-direction: column;
  }

  &__form,
  &__field {
    display: flex;
    flex-direction: column;
  }

  &__field {
    @apply mt-6;
  }

  &__input {
    @apply bg-ls-gray-100 rounded-lg px-2 py-3;
    font-size: 14px;
    line-height: 20px;
  }

  &__label,
  &__error {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }

  &__error {
    @apply text-ls-red mt-1;
  }

  &__label {
    margin-bottom: 0.25rem;
  }

  &__upload-btn {
    @apply mt-4;
  }
}
</style>
